var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ptxt" }, [
      _c("p", { staticStyle: { "text-align": "center" } }, [
        _c(
          "span",
          { staticStyle: { "font-size": "19px", "font-family": "宋体" } },
          [_c("strong", [_vm._v("随心语音用户协议")])]
        ),
      ]),
      _c("p", { staticStyle: { "text-align": "left" } }, [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("特别提示")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("u", [
          _c("strong", [
            _vm._v(
              "在使用随心语音软件(以下简称“本软件”)及服务(以下简称“本服务”)之前,您应当认真阅读并遵守《随心语音用户协议》(以下简称“本协议”)以及《随心语音用户个人信息及隐私保护政策》。请您务必审慎阋读、充分理解各条款内容，特别是免除或者限制责任的条款、争议解决和法律适用条款。免除或者限制责任的条款可能将以加粗字体显示,您应重点阅读"
            ),
          ]),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v("。"),
          _c("br"),
          _c("br"),
          _vm._v(
            "当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后,或您按照激活页面提示填写信息、阅读并同意本协议且完成全部激活程序后,或您以其他随心语音允许的方式实际使用本服务时,即表示您已充分阅读、理解并接受本协议的全部内容,本协议即产生法律约束力。您承诺接受并遵守本协议的约定，届时您不应以未阅读本协议的内容或者未获得随心语音对您问询的解答等理由,主张本协议无效或要求撤销本协议"
          ),
          _c("br"),
          _c("br"),
          _vm._v("【审慎阅读】"),
          _c("br"),
          _c("br"),
        ]),
        _c("u", [
          _c("strong", [
            _vm._v(
              "用户须确认自己已年满18周岁且具有完全民事行为能力。如果您因年龄、智力等因素而不具有完全民事行为能力,请在法定监护人(以下简称“监护人”)的陪同下阅读和判断是否同意本协议。若您不具备前述与您行为相适应的民事行为能力,则您及您的监护人应依照法律规定承担因此而导致的一切后果。"
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "特别地,如果您是未成年人,请在您的监护人的陪同下审阅和接受本协议。未成年人应当在合理程度内使用随心语音,不得因使用随心语音而影响日常的学习生活。您理解随心语音无义务对本用户协议事项进行任何形式的审查和确认。随心语音谨此提醒家长合理引导未成年缔约主体。"
            ),
          ]),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("一、缔约主体")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v(
            "本协议由通过下载、安装、使用、登录随心语音移动客户端以及其他方式使用随心语音服务的用户(以下简称“用户”或“您”)与广西牧纯科技有限公司(以下简称“随心语音或我们”)共同缔结。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("二、协议内容和效力")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v(
            "2.1本协议内容包括本协议正文及所有我们已经发布或将来可能发布的隐私权政策、各项政策、规则、声明、通知、警示、提示、说明(以下简称“规则”)。前述规则为本协议不可分割的组成部分,与本协议具有同等法律效力。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("u", [
          _c("strong", [
            _vm._v(
              "2.2我们有权根据需要不时制订、修改本协议及相关规则,变更后的协议和规则将通过随心语音平台以公告、弹窗、站内信、消息等的形式通知您,变更后的协议和规则一经通知即生效并取代原协议及规则。如您不同意相关变更,应当立即停止使用随心语音服务;如您继续使用随心语音服务或进行任何网站活动,即表示您已接受经修订的协议和规则。"
            ),
          ]),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v(
            "2.3随心语音基于互联网，以包含随心语音平台网站、客户端等在内的各种形态（包括未来技术发展出现的新的服务形态）向您提供的各项服务。您在使用该项服务前请阅读并同意相关的协议，您一旦在本服务中接受或使用衍生服务,即视为您已经阅读并同意接受本协议,受到本协议服务条款的约束。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("三、服务内容")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v(
            "3.1本软件是指我们开发并按照本协议及我们其他协议规则之约定,授权用户下载、安装、登录、使用的随心语音软件。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "3.2您知晓并同意,我们可能会根据需要更新或调整本软件和本服务的内容。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "3.3您可能可以通过电脑、手机等终端以客户端等形式使用本服务,具体以我们提供的为准。同时,我们会不断丰富您使用本服务的终端、形式等。当您使用本服务时,您应选择与您的终端、系统等相匹配的本软件版本,否则,您可能无法正常使用本服务。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "3.4我们给予您一项个人的、不可转让及非排他性的许可,以按照本协议及我们其他协议、规则限定的范围和方式使用本软件。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "3.5本条及本协议其他条款未明示授权的其他一切权利仍由我们保留,您在行使这些权利时须另外取得我们的书面许可。我们如果未行使前述任何权利,并不构成对该权利的放弃。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("四、注册、账号管理及个人信息保护")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v("4.1用户需通过微信、QQ或手机号注册随心语音平台账号。"),
          _c("br"),
          _c("br"),
          _vm._v(
            "4.2您了解并同意，随心语音注册账号所有权归属于我们，注册完成后，您仅获得账号使用权。我们可以根据用户需求或产品需求对账号注册和绑定的方式进行更改，无需事先通知用户。随心语音账号使用权仅归属于申请注册并完成实名认证的用户，具体的实名认证方式，可能包括基于移动电话号码、基于身份证明文件等一种或多种方式的结合，具体将按照您使用的特定服务内容、国家法律法规的特定要求有所差异。禁止账号的赠与、借用、租用、转让或售卖，否则，我们有权立即不经通知收回该账号，甶此带来的因您使用本服务产生的全部数据、信息等被清空、丢失等的损失，您应自行承担。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "4.3用户保证注册随心语音账号时填写的身份信息是真实的，任何非法、不真实、不准确的用户信息所产生的责任甶用户承担。用户应不断更新注册资料，符合及时、详尽、真实、准确的要求。所有原始键入的资料将引用用户的账号注册资料。如果因用户的注册信息不真实而引起的问题，以及对问题发生所带来的后果，随心语音不负任何责任。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "4.4您理解并承诺，您的账户名称、头像和简介等注册信息中不得出现违法和不良信息，没有冒用、关联机构或社会名人，您在账户注册过程中需遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "4.5用户随心语音账号在丢失或遗忘密码后,可遵照随心语音的申诉途径及时申诉请求找回账号。用户应不断提供能增加账号安全性的个人密码保护资料。用户可以凭账号身份认证信息、初始注册资料及个人密码保护资料填写申诉单向随心语音申请找回账号。随心语音的密码找回机制仅负责识别申诉单上所填资料与系统记录资料的正确性,而无法识别申诉人是否系真正账号使用人,对用户因被他人冒名申诉而致的任何损失,随心语音不承担任何责任,用户知晓随心语音账号及密码保管责任在于用户,随心语音并不承诺随心语音账号丢失或遗忘密码后用户一定能通过申诉找回账号。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "4.6为了充分利用随心语音账号资源,如果用户存在长期未登陆使用随心语音账号的情形,随心语音有权对账号进行注销、回收、替换或采取删除该账户在随心语音软件数据库中的任何记录(包括但不限于注册信息、虚拟礼物信息等)等清理措施,相应服务同时终止。随心语音在对此类账户进行清理前,将以包括但不限于弹窗、网站公告、站內消息、客户端推送信息等方式通知。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "4.7请用户注意勿在使用随心语音软件服务中透露自己的各类财产账户、银行卡、信用卡、第三方支付账户及对应密码等重要资料,否则由此带来的任何损失由用户自行承担。"
          ),
          _c("br"),
          _c("br"),
          _vm._v("4.8就下列相关事宜的发生,随心语音软件不承担任何法律责任"),
          _c("br"),
          _c("br"),
          _vm._v(
            "(1)随心语音根据法律规定或相关政府机关、安全机关、司法机关的要求提供用户的个人信息。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "(2)由于用户将用户密码告知他人或与他人共享注册账户,由此导致的任何财产损失、虚拟财产损失、个人信息的泄漏,或其他非因随心语音原因导致的上述损失或泄漏。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "(3)任何由于黑客攻击、电脑病毒侵入造成的任何财产损失、虚拟财产损失、信息泄漏。"
          ),
          _c("br"),
          _c("br"),
          _vm._v("(4)因不可抗力导致的任何财产损失、虚拟财产损失信息泄漏。"),
          _c("br"),
          _c("br"),
        ]),
        _c("u", [
          _c("strong", [
            _vm._v(
              "4.9我们重视对用户个人信息的保护,关于用户的个人信息依《随心语音用户个人信息及隐私保护政策》受到保护与规范,详情请参阅《随心语音用户个人信息及隐私保护政策》。您应理解随心语音中不得出现违法和不良信息，不得冒充他人，不未经许可为他人注册，不得以可能导致其他用户误认的方式注册账号，不得使用可能侵犯他人权益的用户名（包括但不限于涉嫌商标权、名誉权侵权等），否则我们有权不予注册或停止服务并收回账号，因此产生的损失甶您自行承担。"
            ),
            _c("br"),
          ]),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [_c("br")]),
        _c("strong", [_vm._v("五、服务使用规范")]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v(
            "5.1您充分了解并同意，随心语音仅为用户提供服务平台，您应自行对利用服务从事的所有行为及结果承担责任。相应地，您应了解，使用随心语音服务可能发生来自他人非法或不当行为（或信息）的风险，您应自行判断及行动，并自行承担相应的风险。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "5.2用户保证使用本软件发布、传播的信息的真实性、准确性，同时保证不会利用随心语音的服务制作、复制、发布、传播、存储含有下列内容的信息："
          ),
          _c("br"),
          _c("br"),
          _vm._v("(1) 反对宪法所确定的基本原则的；"),
          _c("br"),
          _c("br"),
          _vm._v(
            "(2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；"
          ),
          _c("br"),
          _c("br"),
          _vm._v("(3) 损害国家荣誉和利益的；"),
          _c("br"),
          _c("br"),
          _vm._v("(4) 煽动民族仇恨、民族歧视，破坏民族团结的；"),
          _c("br"),
          _c("br"),
          _vm._v("(5) 破坏国家宗教政策，宣扬邪教和封建迷信的；"),
          _c("br"),
          _c("br"),
          _vm._v("(6) 散布谣言，扰乱社会秩序，破坏社会稳定的；"),
          _c("br"),
          _c("br"),
          _vm._v("(7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；"),
          _c("br"),
          _c("br"),
          _vm._v("(8) 侮辱或者诽谤他人，侵害他人知识产权或其他合法权益的；"),
          _c("br"),
          _c("br"),
          _vm._v("(9) 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；"),
          _c("br"),
          _c("br"),
          _vm._v(
            "(10) 含有虚假、诈骗、有害、胁迫、侵害他人隐私、中伤、低俗、或其它道德上令人反感的内容的；"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "(11) 含有中国或其您所在国国家管辖法所适用的法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "5.3用户不得干扰本软件正常运营，以及侵犯其他用户或第三方合作的权益，包括但不限于以下行为："
          ),
          _c("br"),
          _c("br"),
          _vm._v("(1) 发布宣传色情网站、成人用品网站等色情、低俗内容；"),
          _c("br"),
          _c("br"),
          _vm._v("(2) 发布宣传假冒伪劣产品或未经授权销售的产品内容；"),
          _c("br"),
          _c("br"),
          _vm._v("(3) 发布宣传国家明令禁止或未取得国家许可销售的产品内容；"),
          _c("br"),
          _c("br"),
          _vm._v(
            "(4) 发布宣传游戏代挂、代练、虚假人气、贩卖虚拟货币、平台道具等内容；"
          ),
          _c("br"),
          _c("br"),
          _vm._v("(5) 发布宣传游戏外挂、辅助等内容；"),
          _c("br"),
          _c("br"),
          _vm._v("(6) 发布组织、宣传网络兼职内容；"),
          _c("br"),
          _c("br"),
          _vm._v("(7) 宣传其他平台、网络游戏链接；"),
          _c("br"),
          _c("br"),
          _vm._v("(8) 恶意刷屏、扰乱平台秩序；"),
          _c("br"),
          _c("br"),
          _vm._v(
            "(9) 侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "(10) 含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。"
          ),
          _c("br"),
          _c("br"),
          _vm._v("5.4用户不得进行任何危害计算机网络安全的行为，包括但不限于："),
          _c("br"),
          _c("br"),
          _vm._v("(1) 使用未经许可的数据或进入未经许可的服务器/账户；"),
          _c("br"),
          _c("br"),
          _vm._v(
            "(2) 未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "(3) 未经许可，企图探查、扫描、测试本“软件”系统或网络的弱点或其它实施破坏网络安全的行为；"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "(4) 企图干涉、破坏随心语音系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；"
          ),
          _c("br"),
          _c("br"),
          _vm._v("(5) 伪造TCP/IP数据包名称或部分名称。"),
          _c("br"),
          _c("br"),
          _vm._v(
            "5.5用户不得进行任何破坏我们服务公平性或者其他影响我们软件正常秩序的行为，包括但不限于主动或被动刷分、合伙作弊、使用外挂或者其他的作弊软件、利用BUG (又叫“漏洞”或者“缺陷”）、恶意多开账号等违反公平和诚实信用原则的行为来获得不正当的非法利益，或者利用互联网或其他方式将外挂、作弊软件、BUG公之于众。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "5.6未经我们书面许可，用户不得自行或授权、允许、协助任何第三人对我们软件及其系统进行地址扫描、网络端口扫描、操作系统探测等扫描及/或探测，不得对我们软件上的任何内容进行非法获取从而用于（包括但不限于）：违法销售个人信息、宣传、增加点击量、浏览量、提供付费服务等。"
          ),
        ]),
      ]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _vm._v(
            "“非法获取”是指采取非正常浏览的手段、方式非法获取擅自使用我们软件内任何内容的行为，具体行为包括但不限于：利用机器人、“蜘蛛”(spider)程序、爬虫程序、拟人程序等非自然用户行为或利用BUG (又叫“漏洞”或者“缺陷”）避开、破坏技术措施，对我们软件内的任何内容进行监视、读取、复制、转存、展示、传播、镜像、上载、下载。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("u", [
          _c("strong", [
            _vm._v(
              "5.7若用户违反本协议的相关规定，我们有权根据情节轻重，采取包括但不限于以下处罚措施："
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "(1) 功能封禁：指我们有权视情况对违规账户进行某项或某几项功能禁止使用。"
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "(2) 账号冻结：指我们有权视情况对违规账户进行一定时间的禁止使用。被冻结账户冻结期间随心语音币、钻石不能用于消费，收入、贡献值不能提现、兑换等，被冻结账户冻结期间不可登录、使用。"
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "(3) 设备封禁：指我们有权视情况对违规账户所登录的设备进行一定时间的禁止使用。被设备封禁的登录设备在封禁期间不能登录任何账号。"
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "(4) 永久封号：指我们有权视情况对违规账户进行永久禁止使用并删除账号信息不得再次注册。被永久封号账户内的虚拟货币，将视为违规用户违反本协议的违约金向我们支付。"
            ),
            _c("br"),
          ]),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _vm._v(
            "5.8我们按照本协议及相关平台规则或相关法律法规，限制、冻结或终止用户对我们服务的使用，而由此给用户带来的损失（包括但不限于因用户违规行为导致与第三方发生的纠纷、通过第三方代充值、第三方充值赠与、非登录充值带来的充值损失，通信中断，用户资料、邮件和游戏道具及相关数据等的清空、相关服务中止等），由用户自行承担。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "5.9您须对利用随心语音账号或本服务传送信息的真实性、合法性、无害性、准确性、有效性等全权负责，与用户所传播信息相关的任何法律责任由用户自行承担，与我们无关。如因此给我们或第三方造成损害的，用户应当依法予以赔偿。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("u", [
          _c("strong", [
            _vm._v(
              "5.10您在随心语音的内容消费（内容消费特指：聊天室礼物打赏、动态礼物打赏、消息礼物打赏、直播礼物打赏等）行为，皆完全基于您的自主、自愿行为，内容消费行为一旦发生概不退款，则由用户自行对内容消费负责，我们不承担任何责任。"
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "5.11我们提供的服务中可能包括广告，您充分了解并同意在使用过程中显示我们和第三方供应商、合作伙伴提供的广告。除法律法规明确规定外，您应自行对该广告信息进行的交易负责，对您因该广告信息进行的交易或前述广告商提供的内容遭受损失或损害，我们不承担任何责任。"
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "5.12如您的账户超过一年未登录的，随心语音有权予以进行注销、回收、替换或采取删除您账户在随心语音数据库中的任何记录（包括但不限于注册信息、虚拟货币信息、订单信息等）等清理措施，您的账户将不能再登录随心语音，相应服务同时终止。随心语音在对此类账户进行清理前，将以包括但不限于弹窗、网站公告、站内消息、客户端推送信息等方式通知您。"
            ),
            _c("br"),
          ]),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [_c("br")]),
        _c("strong", [_vm._v("六、付费服务")]),
      ]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _vm._v(
            "6.1本软件部分功能涉及付费，用户注册账号即默认开通随心语音账户，可在平台内进行充值和消费。用户可在我的-我的钱包中查询账户内虚拟货币的充值和消费记录，相关信息将不作为公开信息。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "用户应遵守本软件内的定价规则，不得擅自对本软件内的定价规则进行任何形式的更改，包括但不限于：线上游戏、线上娱乐、虚拟礼物、时长消费等模块。用户不得以任何形式绕开本软件内定价规则进行线上或线下交易，亦不得通过私下交易的方式逃避本软件内定价规则。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "随心语音平台禁止线下交易，若用户出现线下交易违规行为的，随心语音有权对用户身份信息所绑定的所有随心语音账号进行暂停使用、查封、冻结或清空虚拟货币、注销等处理。用户因线下交易造成的一切经济损失由用户自行承担，随心语音将不承担任何责任。"
          ),
          _c("br"),
          _c("br"),
          _vm._v("【特别提示】："),
          _c("br"),
          _c("br"),
        ]),
        _c("u", [
          _c("strong", [
            _vm._v(
              "未成年用户使用随心语音.必须在征得监护人同意的前提下讲行。请未成年人用户监护人切实履行其监护义务。未成年人不得使用随心语音的充值及消费服务，监护人应妥善保管支付账户及支付密码等信息，以避免被监护人在未取得您同意的情況下、打赏。"
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "随心语音已设置“青少年模式”，未经身份证信息验证的用户无法使用充值以和消费。"
            ),
          ]),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
          _vm._v("6.2用户可在软件内充值相应虚拟货币来消费平台提供的服务。"),
          _c("br"),
          _c("br"),
          _vm._v(
            "(1) 随心币：用户确认的随心币可用于本软件提供的购买虚拟道具、发红包服务、充值钻石；"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "(2) 随心钻：用户确认、充值的钻石可用于本软件提供的购买虚拟道具。若用户未及时使用导致道具失效的，由此产生的所有损失均由用户自行承担。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "(3) 爵位：用户在使用本软件期间的等级象征，若用户因未依据平台爵位规则而导致等级降低的，由此产生的所有损失均由用户自行承担。"
          ),
          _c("br"),
          _c("br"),
          _vm._v("6.3充值相关服务介绍："),
          _c("br"),
          _c("br"),
          _vm._v(
            "6.3.1用户可以在充值系统（随心语音平台我们认可的充值系统）上自由选择具体的充值方式，并按相应页面提示的程序完成充值。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "6.3.2用户使用各种充值方式进行充值后，应保留充值订单号或充值卡以作为今后发生问题核对依据凭证（用户就充值服务投诉，但未有充值卡或其他有效凭证佐证的，我们将不会作出补偿或赔偿）"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "6.3.3在使用充值方式时，用户务必仔细确认自己的账号并仔细选择相关操作选项。若因为用户自身输入账号错误、操作不当或不了解充值计费方式等因素造成充错账号、错选充值种类等情形而损害自身权益的，我们将不会作补偿或賠偿。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "6.3.4若用户以非法的方式，或使用非我们所指定的充值方式进行充值，我们不保证该充值顺利或者正确完成。若因此造成用户权益受损时，我们不会作补偿或赔偿，我们同时保留随时终止该用户账号资格及使用各项充值服务的权利。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "6.3.5用户不得通过我们充值进行任何违法犯罪活动，不得利用充值漏洞谋利，否则我们有权终止服务，追缴非法获利，情节严重的，依法移交司法机关。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "6.3.6充值成功后，充值所增加的账号内虚拟货币可由用户自甶使用，我们充值服务提供的产品属于在线交付的充值类商品，因其特殊属性不适用《消费者权益保护法》中有关反悔权及网络购买商品七日无理甶退货等条款的约束，用户一旦充值成功，充值即确定完成，我们不提供退还或逆向兑换服务。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("u", [
          _c("strong", [
            _vm._v(
              "6.3.7各充值方式对应的渠道商可能会按其标准制定相关充值渠道手续费用（可能会在充值操作页面或渠道商其他页面上提示），并在用户充值时收取。我们特此提醒：请用户务必注意各充值方式的渠道商服务手续费，并按自己的需求选取充值方式。"
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "6.3.8若因我们充值方式原因造成用户充值失实并经查证属实的，我们将根据用户充值情况作出变动措施："
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "（1）因充值方式原因，造成系统充值额小于用户实际充值额的，我们予以补其差额；"
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "（2）因充值方式原因，造成系统充值额大于用户实际充值额的，我们有权追回差额；"
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "（3）因渠道商收取服务手续费用等原因造成系统充值额小于用户实际充值额的，不属于用户充值失实。"
            ),
            _c("br"),
          ]),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _vm._v(
            "6.3.9用户请理解充值我们虚拟货币成功后，除法律法规明确规定或本协议另有约定外，虚拟货币在任何情况下不能兑换成法定货币，不能转让他人。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("u", [
          _c("strong", [
            _vm._v(
              "6.3.10对于未成年充值消费、打赏及其他用户利用非法来源资金打赏消费的，随心语音平台有权直接冻结用户账户。若最终被行政机关、司法机关或者随心语音平台判定确为前述消费行为的，随心语音平台有权要求用户将前述所得款项收益退还，若用户拒绝退款的，随心语音有权直接从用户未结算费用中进行扣除，若费用不足时，用户应在随心语音通知后于指定时间内将相应费用补足支付到随心语音指定账户。"
            ),
            _c("br"),
          ]),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _vm._v(
            "6.4用户付费后获得的权益不得转让给第三人，同时用户在本软件中获得的收入也不得转让给第三人。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "6.5当您在随心语音平台内充值、提现、打赏、发红包等资金或虚拟财产相关操作或使用相关功能时，您承诺均会在遵守法律法规、平台各类协议和规则的前提下进行，且所涉资金来源亦符合法律规定。您同意我们基于自身风险判断或依法律法规相关规定，在发现任何可能存在异常、疑异或违法等情况时，我们有权先行暂停或终止您的操作，并根据具体情况采取相关处罚措施（包括但不限于进行功能封禁、冻结资金或帐户等），您理解相关法律责任和损失由您自行承担，该等情况包括但不限于如下："
          ),
          _c("br"),
          _c("br"),
          _vm._v("（1） 基于相关法律法规规定或有权机关的要求；"),
          _c("br"),
          _c("br"),
          _vm._v(
            "（2） 您的帐号、收款账户、交易操作、资金流向等存在异常或存在风险；"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "（3） 您违反了法律法规、公序良俗、社会公德、侵犯他人合法权益等"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "（4） 您的帐号错误汇入资金、存在误操作等导致您可能存在不当获利；"
          ),
          _c("br"),
          _c("br"),
          _vm._v("（5） 您遭受他人投诉，且对方已经提供一定证据的；"),
          _c("br"),
          _c("br"),
          _vm._v(
            "（6） 您可能错误地操作他人帐号，或者将他人帐号进行了身份验证的；"
          ),
          _c("br"),
          _c("br"),
          _vm._v("（7） 您涉嫌参与欺诈、套现或传销活动；"),
          _c("br"),
          _c("br"),
          _vm._v(
            "（8） 您涉嫌参与不法交易行为，如参与不法来源资金、境外资金等洗钱、恐怖融资、赌博及其他我们认为不得使用相关功能的情形；"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "（9） 您涉嫌通过任何不当、违反诚实信用原则的方式（包括但不限于提供虚假信息、反复注销注册、一人多号、服务造假或利用机器人软件/蜘蛛软件等技术手段）使用相关功能；"
          ),
          _c("br"),
          _c("br"),
          _vm._v("（10） 其他违反法律法规、各类平台协议、规则的行为。"),
        ]),
      ]),
      _c("p", [_c("span", { staticStyle: { "font-family": "宋体" } })]),
      _c("p", [_c("strong")]),
      _c("p", [_c("strong", [_vm._v("七、知识产权声明")])]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _vm._v(
            "7.1除本服务中涉及广告的知识产权由相应广告商享有外，我们在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权均归我们所有，但用户在使用本服务前对自己发布的内容已合法取得知识产权的除外。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "7.2除另有特别声明外，我们提供本服务时所依托软件的著作权、专利权及其他知识产权均归我们所有。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "7.3我们在本服务中所涉及的图形、文字或其组成，以及其他我们标志及产品、服务名称（以下统称“我们标识”），其著作权或商标权归我们所有。未经我们事先书面同意，用户不得将我们标识以任何方式展示或使用或作其他处理，也不得向他人表明用户有权展示、使用或其他有权处理我们标识的行为。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "7.4上述及其他任何与我们或相关广告商依法拥有的知识产权均受到法律保护，未经我们或相关广告商书面许可，用户不得以任何形式进行使用或创造相关衍生作品。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("八、法律责任")]),
      ]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [_c("br")]),
        _c("strong", [
          _vm._v(
            "8.1如果我们发现或受到他人举报或投诉用户违反本协议的约定的，我们有权不经通知随时对相关内容，"
          ),
        ]),
        _c("u", [
          _c("strong", [
            _vm._v("包括但不限于用户资料、聊天记录进行审查、删除。"),
          ]),
        ]),
        _c("strong", [
          _vm._v(
            "并视情节轻重对违规账号处以包括但不限于警告、功能封禁、账号冻结、设备封禁、功能封禁、永久封号、账号回收的违规违约处理，且通知用户处理结果，随心语音有权对用户行为及适用的规则进行单方认定，并据此处理。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "8.2用户理解并同意，我们有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此产生的一切法律责任。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "8.3用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿我们与合作公司、关联公司，并使之免收损害。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "8.4用户理解并同意，因用户违反本协议约定，导致我们对其进行永久封号处理的，用户被永久封号后，账户内的现金余额、钻石余额、贡献值将均不得再进行提现。上述现金余额、钻石余额、贡献值将视为违规用户向我们支付的违约金，由我们收取并支配。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "8.5用户（包括但不限于注册用户、封号用户及注销用户等）不得恶意抹黑、诋毁随心语音及其关联公司；不得传播损害随心语音及其关联公司的相关言论及不实报道；不得煽动粉丝等网络群体恶意攻击随心语音及其关联公司。任何用户未经我们书面同意，不得在任何新闻媒体、自媒体等平台上发布有关随心语音及其关联公司的言论或接受有关随心语音及其关联公司的采访。若用户违反上述约定，我们优选根据相关法律规定向其追究法律责任以及主张损失赔偿。"
          ),
          _c("br"),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [_c("br")]),
        _c("strong", [_vm._v("九、不可抗力及其他免责事由")]),
      ]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _vm._v(
            "9.1用户理解并确认,在使用本服务的过程中,可能会遇到不可抗力等风险因素,使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件,包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行动等。出现上述情况时。我们将努力在第一时间与相关单位配合,及时进行修复,但是由此给用户或第三方造成的损失,我们及合作单位在法律允许的范围内免责。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "9.2本服务同大多数互联网服务一样,受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响可能受到各种安全问题的侵扰,如他人利用用户的资料,造成现实生活中的骚扰;用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒,威胁到用户的计算机信息和数据的安全,继而影响本服务的正常使用等等。用户应加强信息安全及使用者资料的保护意识,要注意加强密码保护,以免遭致损失和骚扰。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [
          _vm._v(
            "9.3用户理解并确认,本服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险,因此导致的用户或第三方任何损失,我们不承担任何责任。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "9.4用户理解并确认,在使用本服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息,或侵犯他人权利的匿名或冒名的信息,以及伴随该等信息的行为,因此导致的用户或第三方的任何损失,我们不承担任何责任。"
          ),
          _c("br"),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _vm._v(
            "9.5用户理解并确认,我们需要定期或不定期地对随心语音平台或相关的设备进行检修或者维护,如因此类情况而造成服务在合理时间内的中断,我们无需为此承担任何责任,但我们应事先进行通知。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "9.6我们依据法律法规、本协议约定获得处理违法违规或违约内容的权利,该权利不构成我们的义务或承诺,我们不能保证及时发现违法违规或违约行为或进行相应处理。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "9.7用户理解并确认,对于我们向用户提供的下列产品或者服务的质量缺陷及其引发的任何损失,我们无需承担任何责任."
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("u", [
          _c("strong", [
            _vm._v(
              "9.8在任何情况下，我们均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害负责。尽管本协议中可能含有相悖的规定，我们对用户承担的全部责任无论因何原因或何种行为方式，始终不超过用户最近三个月因使用我们提供的服务而支付给我们的费用。"
            ),
          ]),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("十、服务的变更、中断、终止")]),
      ]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _vm._v(
            "10.1鉴于网络服务的特殊性，用户同意我们有权随时变更、中断或终止部分或全部的服务(包括收费服务)我们变更、中断或终止的服务，我们应当在变更、中断或终止之前通知用户，并应向受影响的用户提供等值的替代性的服务。"
          ),
          _c("br"),
          _c("br"),
        ]),
        _c("u", [
          _c("strong", [
            _vm._v(
              "10.2如发生下列任何一种情形，我们有权变更、中断或终止向用户提供的免费服务或收费服务，而无需对用户或任何第三方承担任何责任："
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "(1)根据法律规定用户应提交真实信息，而用户提供的个人资料不真实或与注册时信息不一致又未能提供合理证明；"
            ),
            _c("br"),
            _c("br"),
            _vm._v("(2)用户违反相关法律法规或本协议的约定；"),
            _c("br"),
            _c("br"),
            _vm._v("(3)按照法律规定或有权机关的要求；"),
            _c("br"),
            _c("br"),
            _vm._v("(4)出于安全的原因或其他必要的情形。"),
          ]),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _c("br"),
        ]),
        _c("strong", [_vm._v("十一、其他")]),
      ]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [
          _c("br"),
          _vm._v(
            "11.1我们郑重提醒用户注意本协议中免除我们责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。"
          ),
          _c("br"),
          _c("br"),
          _vm._v(
            "11.2本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。"
          ),
          _c("br"),
          _c("br"),
        ]),
      ]),
      _c("p", [
        _c("strong", [_vm._v("【未成年保护】：")]),
        _c("br"),
        _c("br"),
        _c("u", [
          _c("strong", [
            _vm._v(
              " (1)未成年在使用随心语音及相关服务应该在其监护人的监督指导下，在合理范围内正确学习使用网络，避免沉迷虚拟的网络空间，养成良好上网习惯；"
            ),
            _c("br"),
            _c("br"),
            _vm._v(" (2)青少年用户必须遵守《全国青少年网络文明公约》；"),
            _c("br"),
            _c("br"),
            _vm._v(" (3)要善于网上学习，不浏览不良信息；"),
            _c("br"),
            _c("br"),
            _vm._v(" (4)要诚实友好交流，不侮辱欺诈他人；"),
            _c("br"),
            _c("br"),
            _vm._v(" (5)要增加自护意识，不随意约会网友；"),
            _c("br"),
            _c("br"),
            _vm._v(" (6)要维护网络安全，不破坏网络秩序；"),
            _c("br"),
            _c("br"),
            _vm._v(" (7)要有益身心健康，不沉溺虚拟时空。 "),
          ]),
        ]),
        _c("br"),
        _c("br"),
      ]),
      _c("strong", [_vm._v("十二、法律适用与管辖")]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [_c("br")]),
        _c("u", [
          _c("strong", [
            _vm._v(
              "本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本协议产生之争议,均应依照中华人民共和国法律予以处理,双方应首先友好协商,若在争议发生后协商不成,应将争议提请上海仲裁委员会照该会仲裁规则进行仲裁解决，仲裁是终局的，对各方均有约束力。"
            ),
            _c("br"),
          ]),
        ]),
        _c("span", { staticStyle: { "font-family": "宋体" } }, [_c("br")]),
        _c("strong", [_vm._v("十三、特别声明")]),
      ]),
      _c("p", [
        _c("span", { staticStyle: { "font-family": "宋体" } }, [_c("br")]),
        _c("u", [
          _c("strong", [
            _vm._v("广西牧纯科技有限公司在此依法做出特别声明："),
            _c("br"),
            _c("br"),
            _vm._v(
              "如我们采取合理的方式提请用户注意的义务将通过如下方式实现:在本协议中我们以明确的足以引起用户注意的加重字体、斜体、下划线、颜色标记等合理方式提醒用户注意相关条款(需要强调的是,还包括用户应特别注意任何未明确标记的含有“不承担”、“免责”、“不得”等形式用语的条款),该等条款的确认将导致用户在特定情况下的被动、不便、损失,请用户在确认同意本协议之前再次阅读上述条款。"
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "用户如有任何需要说明条款的要求,请立即停止使用服务, 同时立即发送邮件至82002598@QQ.COM,若用户未致电或发送有说明条款要求的信息至广西牧纯科技有限公司而选择同意该协议,则双方在此确认我们已依法履行了根据用户要求对相关条款进行说明的法定义务,我们已给予用户充足的时间与充分的选择权来决定是否缔结本协议。"
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "鉴于我们已依法明确了上述条款、履行了格式条款制订方的义务,用户点击同意或下一步,将被视为且应当被视为用户已经完全注意并同意了本协议所有条款尤其是提醒用户注意的条款的合法性及有效性,用户不应当以我们未对格式条款以合理方式提醒用户注意,或未根据用户要求尽到说明义务为理由而声称或要求法院或其它任何第三方确认相关条款非法或无效。"
            ),
          ]),
        ]),
      ]),
      _c("p", [_c("br")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }