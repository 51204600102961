<template>
    <div class="payBox">
        <div class="box GiftCom">
            <!-- <img class="iconClose" src="@/assets/imgs/icon_close.png" alt="" @click="closeFun"> -->
            <i class="el-icon-close closebtn" @click="closeFun"></i>
            <div class="top">需要支付 <span class="money">{{ payMoney }}</span> 元</div>
            <!-- 微信支付二维码 -->
            <div class="qrCodeBox d-flex a-center j-center" v-if="payInfo">
                <QrCodeCom :text="payInfo.codeUrl" :logoSrc="require('@/assets/imgs/icon_logo.png')" :size="230"
                    :logoSize="60" withLogo />
                <div class="refreshBox " v-if="isTimeOut">
                    <img class="iconRefresh" :src="require('@/assets/imgs/icon_refresh.png')" alt="" @click="refrechHandle">
                    <div class="text">支付已超时，请刷新</div>
                </div>
            </div>

            <div class="desc">严禁利用收款码进行诈骗，发现后会立即通知</div>
            <div class="tips">请使用微信扫码支付</div>
        </div>
    </div>
</template>

<script>
import QrCodeCom from '@/components/QrcodeCom.vue';
export default {
    name: 'payPopCom',
    components: {
        QrCodeCom
    },
    props: {
        isShowPayPop: {
            type: Boolean,
            default: false
        },
        payInfo: {
            type: Object,
        },
        payMoney: {
            type: Number,
            default: null
        },
        isTimeOut: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {

        }
    },
    created() {

    },
    methods: {
        // 关闭支付弹窗
        closeFun() {
            this.$emit('closeWechatPay')
        },

        // 刷新支付二维码
        refrechHandle() {
            this.$emit('refrechWechatPay')
        },
    },
}
</script>

<style lang="less">
.payBox {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);

    .box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 420px;
        height: 420px;
        background: #3F314A;
        border-radius: 24px;
        border: 1px solid #755C88;
        filter: blur(0px);
        position: relative;

        .closebtn {
            width: 16px;
            object-fit: cover;
            display: block;
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
            color: #999;
        }

        .top {
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
            height: 40px;
            text-align: center;
            margin-top: 20px;

            .money {
                font-size: 32px;
                font-family: Impact;
                color: #B586F2;
            }
        }

        .qrCodeBox {
            position: relative;
            background: #FFFFFF;
            height: 256px;
            width: 256px;
            margin: 24px auto 22px;
            border-radius: 12px;

            .refreshBox {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: rgba(63, 49, 74, 0.8);

                .iconRefresh {
                    height: 80px;
                    width: 80px;
                    display: block;
                    margin: 70px auto 0;
                }

                .text {
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: center;
                    margin-top: 12px;
                }
            }

            #qrcodeImg {
                height: 256px;
                width: 256px;
                border-radius: 12px;
            }
        }



        .desc {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #B586F2;
            text-align: center;
            margin-bottom: 6px;
        }

        .tips {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.5);
            text-align: center;
        }
    }
}
</style>
