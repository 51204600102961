var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { ref: "qrcodeContainer", staticStyle: { display: "none" } }),
    _vm.qrcodeImageUrl
      ? _c("img", { attrs: { src: _vm.qrcodeImageUrl, alt: "QR Code" } })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }