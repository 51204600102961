<template>
	<div class="recharge flex-1">
		<p class="rechargeTit">
			<img src="../assets/imgs/zhcz.png" />
		</p>
		<div class="rechargeBox">
			<ul>
				<li>
					<p class="reL">账户钻石</p>
					<div class="reR">
						<p class="jc">{{ balance.diamond }}</p><img src="../assets/imgs/z.png" />
					</div>
				</li>
				<li>
					<p class="reL">充值金额</p>
					<div class="reR">
						<ul class="moneylist d-flex a-center">
							<li :class="{ moneyli: moneysel == index }" v-for="(item, index) in moneylist" :key="item.id"
								@click="moneyClick(item, index)">
								<span class="price">{{ item.rmb }}</span>
								<span class="unit">元</span>
							</li>
							<li :class="[{ moneyli: isFocus }, 'liInput']">
								<input placeholder="请输入自定义金额" v-model="diymoney" @focus="inputFocus" @blur="inputBlur"
									@input="inputHandle" />
								<span class="unit">元</span>
							</li>
						</ul>
					</div>
				</li>
				<li>
					<p class="reL">获得钻石</p>
					<div class="reR">
						<p class="jc">{{ diamond }}</p><img src="../assets/imgs/z.png" />
						<p class="bl">（兑换比例 1元 : 10钻石）</p>
					</div>
				</li>
				<li>
					<p class="reL">充值方式</p>
					<div class="reR">
						<ul class="zflist d-flex a-center">
							<li class="d-flex a-center j-center" v-for="item in channelList" :key="item.type"
								:class="{ zfli: zfType == item.type }" @click="zfClick(item.type)">
								<img class="iconPayType"
									:src="item.type == 1 ? require('@/assets/imgs/wxzf.png') : require('@/assets/imgs/zfb.png')" />
								<span>{{ item.title }}</span>
							</li>
						</ul>
					</div>
				</li>
				<li>
					<p class="reL"></p>
					<div class="reR">
						<button @click="payHandle">立即充值</button>
						<p>有问题请联系客服QQ：1058127957</p>
					</div>
				</li>
				<li>
					<p class="reL"></p>
					<div class="reR">
						<p class="reTip">注：钻石用于平台礼物的购买，充值后将不可提现。</p>
					</div>
				</li>
			</ul>
		</div>
		<div class="aliform" v-html="aliform"></div>

		<PayPopCom v-if="isShowPayPop" :payInfo="payInfo" :payMoney="payMoney" :isTimeOut="isTimeOut"
			@refrechWechatPay="refrechWechatPay" @closeWechatPay="closeWechatPay"></PayPopCom>
	</div>
</template>

<script>
import {
	getPayList,
	getWxCodeUrl,
	aliPcPay,
	getBalance,
	getPayStatus,
	canceAxios,
	getRechargeChannel
} from "@/util/api/api.js"
import { mapActions } from "vuex";
import PayPopCom from '@/components/payPopCom.vue'

import axios from "axios";
const source = axios.CancelToken.source();

console.log(source)

export default {
	components: {
		PayPopCom
	},
	props: {},
	data() {
		return {
			balance: {
				diamond: 0,
				coin: 0
			},// 用户余额
			diymoney: null,
			zfType: null, // 当前所选支付类型
			moneylist: [],
			moneysel: null,
			isFocus: false,//是否聚焦输入框

			isShowPayPop: false,// 支付弹窗是否展示
			isTimeOut: false,// 微信支付是否超时
			payInfo: {},// 支付信息（微信）
			payMoney: null, // 支付金额
			aliform: null,
			channelList: [],// 充值渠道列表
		}
	},
	computed: {
		diamond() {
			let _diamond = 0
			if (this.isFocus) {
				_diamond = this.diymoney ? Number(this.diymoney) * 10 : 0
			} else if (this.moneysel != null && this.moneysel >= 0 && this.moneylist.length > 0) {
				_diamond = this.moneylist[this.moneysel].diamond
			}
			return _diamond
		}
	},
	watch: {
		$route(to) {
			console.log(to)
			if (to.query && to.query.out_trade_no) {
				let params = {
					orderNumber: to.query.out_trade_no,
					platform: 2, //支付平台:1微信，2支付宝
				}
				this._getPayStatus(params)
			}
		},

	},
	async created() {
		this._getPayList()
		this._getRechargeChannel()
		if (this.$route.query && this.$route.query.out_trade_no) {
			console.log(this.$route.query)
			let params = {
				orderNumber: this.$route.query.out_trade_no,
				platform: 2, //支付平台:1微信，2支付宝
			}
			await this._getPayStatus(params)
		}
		await this._getBalance()
	},
	methods: {
		...mapActions(["setBalance"]),
		// 获取当前用户余额
		async _getBalance() {
			try {
				let res = await getBalance()
				if (res.data) {
					this.balance = {
						diamond: res.data.diamond || 0,
						coin: res.data.coin || 0
					}
					// 更新用户余额信息
					this.setBalance(this.balance);
				}
			} catch (error) {
				console.log(error)
			}
		},
		// 获取充值列表
		async _getPayList() {
			try {
				let res = await getPayList({ type: 1 })
				if (res.data && res.data.list) {
					this.moneylist = res.data.list
				}
			} catch (error) {
				console.log(error)
			}
		},

		// 获取支付渠道列表
		async _getRechargeChannel() {
			try {
				let res = await getRechargeChannel()
				if (res.data && res.data.list) {
					this.channelList = res.data.list
				}
			} catch (error) {
				console.log(error)
			}
		},

		// 充值金额tab切换
		moneyClick(item, index) {
			this.moneysel = index
			this.isFocus = false
			this.diymoney = null
		},

		// 选择支付方式
		zfClick(type) {
			this.zfType = type
		},

		// 输入框聚焦
		inputFocus() {
			// console.log('聚焦输入框')
			this.isFocus = true
			this.moneysel = null
		},

		// 金额输入校验
		inputHandle(val) {
			if (val.target.value.length == 1) {
				this.diymoney = val.target.value.replace(/[^1-9]/g, '')
			} else {
				this.diymoney = val.target.value.replace(/\D/g, '')
			}
		},

		// 金额输入框失去焦点
		inputBlur() {

		},

		// 充值
		payHandle() {
			if (this.moneysel == null && !this.isFocus) {
				this.$message({
					message: '请选择充值金额',
					offset: 350,
					type: 'warning'
				});
				return
			}

			if (this.isFocus && !this.diymoney) {
				this.$message({
					message: '请输入大于0的充值金额',
					offset: 350,
					type: 'warning'
				});
				return
			}

			if (this.zfType == null) {
				this.$message({
					message: '请选择支付方式',
					offset: 350,
					type: 'warning'
				});
				return
			}

			// 自定义支付
			if (this.isFocus != null) {
				this.payMoney = Number(this.diymoney)
			}
			// 选择支付
			if (this.moneysel != null) {
				this.payMoney = Number(this.moneylist[this.moneysel].rmb)
			}

			if (this.zfType == 1) {
				this._getWxCodeUrl()
			} else {
				this._aliPcPay()
			}
		},

		// 获取微信支付信息
		async _getWxCodeUrl() {
			try {
				let params = {
					regmoney: this.payMoney
				}
				var loading = this.$loading({
					lock: true,
					text: '支付订单创建中...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});

				let res = await getWxCodeUrl(params)
				if (res.resultCode === '000000') {
					this.isShowPayPop = true
					this.payInfo = res.data
					this.isTimeOut = false
					this.time = 60
					// 判断二维码是否超时
					this.timer = setInterval(async () => {
						if (this.time <= 0) {
							this.isTimeOut = true
							clearInterval(this.timer)
							this.timer = null
							clearInterval(this.timer2)
							this.timer2 = null
							return
						}
						this.time--
					}, 1000);

					// 轮询查订单状态
					this.timer2 = setInterval(async () => {
						let _params = {
							orderNumber: this.payInfo.outTradeNo,
							platform: this.zfType, //支付平台:1微信，2支付宝
						}
						await this._getPayStatus(_params)
					}, 3000);
				}
			} catch (error) {
				console.log(error)
			} finally {
				loading.close()
			}
		},

		// 支付宝支付
		async _aliPcPay() {
			try {
				let params = {
					regmoney: this.payMoney
				}
				let res = await aliPcPay(params)
				if (res.resultCode === '000000') {
					this.aliform = res.data.body;  //data.data就是支付宝返回给你的form,获取到的表单内容,具体样子可见上面的图片
					this.$nextTick(() => {
						// 获取订单详情来轮询支付结果
						console.log(document.forms);  //跳转之前,可以先打印看看forms,确保后台数据和forms正确,否则，可能会出现一些奇奇怪怪的问题 ╮(╯▽╰)╭
						document.forms[0].submit();  //重点--这个才是跳转页面的核心,获取第一个表单并提交
					})
				}
			} catch (error) {
				console.log(error)
			}
		},

		// 获取充值支付状态
		async _getPayStatus(params) {
			// 上次请求还没返回则不重新请求
			if (this.isGetPayStatus) {
				return
			}
			// 支付宝展示支付loading
			if (params.platform == 2) {
				var loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
			}
			console.log(params)
			try {
				this.isGetPayStatus = true
				let res = await getPayStatus(params)
				if (res.resultCode === '000000') {
					this.isGetPayStatus = false
					this.payStatus = res.data.payStatus
					if (loading) {
						loading.close();
					}
					if (res.data.payStatus) {

						// 存在定时器则关闭
						if (this.timer2) {
							clearInterval(this.timer2)
							this.timer2 = null
						}

						// 微信支付展示支付成功提示
						if (params.platform == 1) {
							this.$message({
								message: '支付成功',
								offset: 80,
								type: 'success'
							});
							this.closeWechatPay(1)
						}


						this.balance = {
							diamond: res.data.diamond || 0,
							coin: res.data.coin || 0
						}

						// 更新用户余额信息
						this.setBalance(this.balance)
					}
				}
				if (loading) {
					loading.close();
				}
			} catch (error) {
				console.log(error)
				this.isGetPayStatus = false
				if (loading) {
					loading.close();
				}
			}
		},

		// 刷新微信支付
		refrechWechatPay() {
			this._getWxCodeUrl()
			this.isTimeOut = false
		},

		// 关闭微信支付弹窗
		closeWechatPay(type) {
			this.isShowPayPop = false
			this.isTimeOut = false
			this.isGetPayStatus = false

			!type && canceAxios("请求被取消了");

			// 清除微信支付二维码超时定时器
			clearInterval(this.timer)
			this.timer = null
			this.time = null
			// 清除轮询订单状态定时器
			clearInterval(this.timer2)
			this.timer2 = null
		}
	},
	destroyed() {
		// 清除微信支付二维码超时定时器
		clearInterval(this.timer)
		this.timer = null
		this.time = null
		// 清除轮询订单状态定时器
		clearInterval(this.timer2)
		this.timer2 = null
	}
}
</script>

<style lang="less" scoped>
.recharge {
	width: 100%;
	min-height: 792px;
	background: url(../assets/imgs/czbg.jpg) no-repeat center/cover;
	position: relative;

	.rechargeTit {
		width: 410px;
		height: 105px;
		position: absolute;
		top: 85px;
		right: 20%;
	}

	.rechargeBox {
		width: 1128px;
		height: 434px;
		background: rgba(63, 49, 74, 0.9);
		border-radius: 16px;
		opacity: 0.9;
		position: absolute;
		top: 225px;
		left: 50%;
		margin-left: -600px;
		padding: 54px 0 0 72px;

		ul {
			color: #fff;

			li {
				height: 50px;
				margin-bottom: 20px;
				line-height: 50px;
				overflow: hidden;

				.reL {
					width: 88px;
					text-align: left;
					float: left;
					font-size: 16px;
					height: 50px;
				}

				.reR {
					float: left;
					width: 1000px;

					.reTip {
						font-size: 12px;
					}

					button {
						width: 160px;
						height: 40px;
						background: linear-gradient(90deg, #705DFF 0%, #B586F2 100%);
						border-radius: 20px;
						float: left;
						line-height: 40px;
						text-align: center;
						font-size: 14px;
						color: #fff;
						margin-right: 24px;
						cursor: pointer;
					}

					.jc {
						font-size: 20px;
						font-weight: bold;
						float: left;
					}

					img {
						float: left;
						width: 16px;
						margin: 17px 0 0 8px;
					}

					.bl {
						color: #b4adbf;
						font-size: 16px;
						margin-left: 30px;
						float: left;
					}

					.zflist {

						li {
							width: 130px;
							height: 48px;
							line-height: 48px;
							background: rgba(255, 255, 255, 0.1);
							border-radius: 8px;
							margin-right: 16px;
							box-sizing: border-box;
							cursor: pointer;
							text-align: center;

							.iconPayType {
								width: 32px;
								height: 32px;
								display: block;
								margin: 0;
								margin-right: 6px;
							}

							span {
								font-size: 16px;
							}

							&.zfli {
								background: rgba(181, 134, 242, 0.1);
								border: 1px solid #B586F2;
							}
						}
					}

					.moneylist {
						li {
							width: 104px;
							height: 48px;
							line-height: 48px;
							background: rgba(255, 255, 255, 0.1);
							border-radius: 8px;
							margin-right: 16px;
							cursor: pointer;
							text-align: center;
							box-sizing: border-box;

							span {
								font-size: 12px;
								margin-left: 4px;
							}

							.price {
								font-size: 20px;
								font-family: Impact;
								color: #FFFFFF;
							}

							.unit {
								font-size: 12px;
								font-family: PingFangSC-Regular, PingFang SC;
								font-weight: 400;
								color: #FFFFFF;
							}

							&.moneyli {
								line-height: 46px;
								background: rgba(181, 134, 242, 0.1);
								border: 1px solid #B586F2;
							}

							&.liInput {
								width: 240px;
								height: 48px;

								input {
									width: 169px;
									height: 40px;
									border: none;
									background: none;
									outline: none;
									font-size: 16px;
									font-family: PingFangSC-Semibold, PingFang SC;
									font-weight: 600;
									color: #FFFFFF;
								}
							}
						}
					}

				}
			}
		}
	}
}
</style>
