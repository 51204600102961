var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "payBox" }, [
    _c("div", { staticClass: "box GiftCom" }, [
      _c("i", {
        staticClass: "el-icon-close closebtn",
        on: { click: _vm.closeFun },
      }),
      _c("div", { staticClass: "top" }, [
        _vm._v("需要支付 "),
        _c("span", { staticClass: "money" }, [_vm._v(_vm._s(_vm.payMoney))]),
        _vm._v(" 元"),
      ]),
      _vm.payInfo
        ? _c(
            "div",
            { staticClass: "qrCodeBox d-flex a-center j-center" },
            [
              _c("QrCodeCom", {
                attrs: {
                  text: _vm.payInfo.codeUrl,
                  logoSrc: require("@/assets/imgs/icon_logo.png"),
                  size: 230,
                  logoSize: 60,
                  withLogo: "",
                },
              }),
              _vm.isTimeOut
                ? _c("div", { staticClass: "refreshBox" }, [
                    _c("img", {
                      staticClass: "iconRefresh",
                      attrs: {
                        src: require("@/assets/imgs/icon_refresh.png"),
                        alt: "",
                      },
                      on: { click: _vm.refrechHandle },
                    }),
                    _c("div", { staticClass: "text" }, [
                      _vm._v("支付已超时，请刷新"),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "desc" }, [
        _vm._v("严禁利用收款码进行诈骗，发现后会立即通知"),
      ]),
      _c("div", { staticClass: "tips" }, [_vm._v("请使用微信扫码支付")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }