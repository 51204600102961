<template>
    <div>
        <!-- 用于生成二维码的临时容器 -->
        <div ref="qrcodeContainer" style="display:none;"></div>
        <!-- 显示最终生成的二维码图片 -->
        <img :src="qrcodeImageUrl" alt="QR Code" v-if="qrcodeImageUrl" />
    </div>
</template>

<script>
import QRCode from 'qrcodejs2';

export default {
    name: 'QrCodeWithLogo',
    props: {
        text: {
            type: String,
            required: true
        },
        logoSrc: {
            type: String,
            default: ''  // 默认没有 Logo
        },
        size: {
            type: Number,
            default: 300
        },
        logoSize: {
            type: Number,
            default: 60
        },
        withLogo: {
            type: Boolean,
            default: false  // 默认生成不带 Logo 的二维码
        }
    },
    watch: {
        text() {
            console.log('链接更新，重新生成二维码')
            this.generateQRCode()
        }
    },
    data() {
        return {
            qrcodeImageUrl: ''  // 用于存储最终的二维码图片URL
        };
    },
    mounted() {
        this.generateQRCode();
    },
    methods: {
        generateQRCode() {
            // 清空临时容器内容
            this.$refs.qrcodeContainer.innerHTML = '';

            // 生成二维码
            const qrcode = new QRCode(this.$refs.qrcodeContainer, {
                text: this.text,
                width: this.size,
                height: this.size,
                correctLevel: QRCode.CorrectLevel.H  // 设置高容错级别
            });

            // 如果不需要 Logo，直接生成图片并返回
            if (!this.withLogo || !this.logoSrc) {
                setTimeout(() => {
                    const canvas = this.$refs.qrcodeContainer.querySelector("canvas");
                    if (canvas) {
                        this.qrcodeImageUrl = canvas.toDataURL("image/png");
                        // 清空二维码生成容器
                        this.$refs.qrcodeContainer.innerHTML = '';
                    }
                }, 100);  // 适当延迟，确保二维码生成完毕
                return;
            }

            // 需要绘制 Logo 的情况
            setTimeout(() => {
                const canvas = this.$refs.qrcodeContainer.querySelector("canvas");
                if (!canvas) {
                    console.error("二维码生成失败，找不到canvas元素");
                    return;
                }

                const ctx = canvas.getContext("2d");

                // 创建图片对象，用于加载 Logo
                const img = new Image();
                img.src = this.logoSrc;

                img.onload = () => {
                    const logoSize = this.logoSize;
                    const centerX = (canvas.width - logoSize) / 2;
                    const centerY = (canvas.height - logoSize) / 2;

                    // 在二维码上绘制 Logo
                    ctx.drawImage(img, centerX, centerY, logoSize, logoSize);

                    // 将带有 Logo 的二维码转换为图片URL
                    this.qrcodeImageUrl = canvas.toDataURL("image/png");

                    // 清空二维码生成容器，避免页面上显示两个二维码
                    this.$refs.qrcodeContainer.innerHTML = '';
                };

                img.onerror = (err) => {
                    console.error("Logo 图片加载失败:", err);
                };
            }, 100);  // 适当延迟，确保二维码生成完毕
        }
    }
};
</script>

<style scoped>
.qrcode {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
