<template>
    <div class="dlApp">
        <img class="bgImg" src="../assets/bg_download.jpg" />
    </div>
</template>

<script>
import {
    getVersionLatest
} from "@/util/api/api.js"
export default {
    data() {
        return {
            iosApp: false,
            iosUrl: '',
            androidUrl: ''
        }
    },

    async created() {
        if (this.isWeixin()) {
            this.iosApp = true
        } else {
            //显示手动打开外置浏览器提示
            this.iosApp = false
            this.openApp()
        }
        await this._getVersionLatest()
    },
    methods: {
        // 获取下载地址
        async _getVersionLatest() {
            try {
                let res = await getVersionLatest()
                if (res.resultCode == '000000') {
                    this.iosUrl = res.data.ios
                    this.androidUrl = res.data.android
                    let ids = this.iosUrl.split('/')
                    console.log(this.iosUrl)
                    console.log(`itms-apps://itunes.apple.com/app/${ids[ids.length - 1]}`)
                }
            } catch (error) {
                console.log(error)
            }
        },
        // 判断是否为微信
        isWeixin() {
            return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
        },
        openApp() {
            // 非微信浏览器
            if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
                const loadDateTime = +new Date();
                setTimeout(() => {
                    const timeOutDateTime = +new Date();
                    if ((timeOutDateTime - loadDateTime) < 5000) {
                        // let ids = this.iosUrl.split('/')
                        // // console.log(`itms-apps://itunes.apple.com/app/${ids[ids.length - 1]}`)
                        // // window.location.href = `itms-apps://itunes.apple.com/app/${ids[ids.length - 1]}`
                        window.location.href = this.iosUrl
                    } else {
                        window.close()
                    }
                }, 2000);
                // window.location.href = 'sxyyurl://'
            } else if (navigator.userAgent.match(/android/i)) {
                try {
                    // window.location.href = this.androidUrl
                    setTimeout(() => {
                        window.location.href = this.androidUrl
                    }, 500);
                } catch (e) {
                    console.log(e)
                }
            }
        },
    }
}
</script>

<style lang="less" scoped>
.dlApp {
    .bgImg {
        width: 100%;
        display: block;
    }
}
</style>
